import { clientOnly } from '@solidjs/start';
import type { Component } from 'solid-js';

const Signer = clientOnly(() => import('~/pages/pdfSigner/Signer'));

const LeaseSigner: Component = () => {
  return <Signer />;
};

export default LeaseSigner;
